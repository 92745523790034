import axios from "axios";

const siteId = process.env.REACT_APP_SITE_ID || "";
const storeId = process.env.REACT_APP_STORE_ID || "";
const serverId = process.env.REACT_APP_SERVER_ID || "";

// Create an axios instance with predefined headers and settings.
const axiosInstance = axios.create({
  //   headers: {
  //     "x-site-id": Config.SITE_ID,
  //     Authorization: `Bearer ${Config.Token}`,
  //   },
  baseURL: process.env.REACT_APP_API_BASE_URL || "",
  maxBodyLength: Infinity,
});

export const loginPin = async (pin: string): Promise<any> => {
  try {
    // Note how the URL is now simplified
    const response = await axiosInstance.post(
      `/customer/login-si180-pin/${serverId}/${siteId}`,
      {
        pin,
      }
    );
    return response.data;
  } catch (error) {
    console.error("Login error:", error);
    throw error;
  }
};

export const fetchCustomer = async (q: string): Promise<any> => {
  try {
    const response = await axiosInstance.get(`/customer/lookup-customer/${serverId}/${siteId}/${q}`);
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const fetchMenu = async (): Promise<any> => {
  try {
    const response = await axiosInstance.get(`/items/menu-180/${serverId}/${siteId}`);
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const fetchMenuCategories = async (): Promise<any> => {
  try {
    const selectedMenu = sessionStorage.getItem("selectedMenu");
    
    const response = await axiosInstance.get(`/items/menu-categories-180/${serverId}/${siteId}/${selectedMenu}`);
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

// https://ecommv2.servingintel.com/items/menu-all-categories-180/2/248/Breakfast/
export const fetchAllMenuItems = async (): Promise<any> => {
  try {
    const selectedMenu = sessionStorage.getItem("selectedMenu");
    const customerNumber = JSON.parse(sessionStorage.getItem("selectedCustomer"))?.CustomerNumber;

    const response = await axiosInstance.get(`/items/menu-all-categories-180/${serverId}/${siteId}/${selectedMenu}?customerNumber=${customerNumber}`);
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const fetchCategoryItems = async (): Promise<any> => {
  
  try {
    const selectedMenu = sessionStorage.getItem("selectedMenu");
    const selectedCategory = sessionStorage.getItem("selectedCategory");
    const selectedOrderOption = sessionStorage.getItem("selectedOrderOption");
    // Safely parse selectedCustomer and retrieve CustomerNumber with a fallback of 0 if it's not available
    const customerNumber = JSON.parse(sessionStorage.getItem("selectedCustomer"))?.CustomerNumber;

    const response = await axiosInstance.get(`/items/category-items/${serverId}/${siteId}/${selectedMenu}/${selectedCategory}/${selectedOrderOption}?customerNumber=${customerNumber}`);
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const searchItems = async (keyWord): Promise<any> => {
  try {
    const trimmedKeyword = keyWord.trim();
    const response = await axiosInstance.get(`/items/search-items/${serverId}/${siteId}/${trimmedKeyword}`);
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const fetchTableLocation = async (): Promise<any> => {
  try {
    const response = await axiosInstance.get(`/customer/tables/${serverId}/${siteId}`);
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const fetchItemMods = async (id: string): Promise<any> => {
  try {
    const response = await axiosInstance.get(`/items/mods/${siteId}/${id}?server_id=${serverId}`);
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const setOrder = async (payload: object): Promise<any> => {
  try {
    const response = await axiosInstance.post(`/postpayment/setOrder/${siteId}?server_id=${serverId}`, payload);
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const sendOrder = async (): Promise<any> => {
  try {
    // Safely retrieve order_id from sessionStorage
    const orderId = sessionStorage.getItem("order_id");
    if (!orderId) {
      throw new Error("Order ID not found in session storage.");
    }

    const response = await axiosInstance.get(
      `/postpayment/send_econnect/${siteId}/${storeId}/${orderId}?server_id=${serverId}`
    );
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const sendOrderForLater = async (): Promise<any> => {
  try {
    // Safely retrieve order_id from sessionStorage
    const orderId = sessionStorage.getItem("order_id");
    if (!orderId) {
      throw new Error("Order ID not found.");
    }

    const response = await axiosInstance.get(
      `/postpayment/print-order-later/${serverId}/${siteId}/${storeId}/${orderId}`
    );
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};
